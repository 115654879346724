.ShapesScreen {
    font-family: Arial, sans-serif;
    color: #333;
}
.ShapesScreen .title{
	color: #43757B;
	font-size: 0.33rem;
	margin: 0.3rem 0 0.2rem 0;
	font-weight: 600;
}

.ShapesScreen .cont{
	color: #333;
	width: 50%;
	font-size: 0.14rem;
	line-height: 0.2rem;
}

.ShapesScreen .btn {
    background-color: #FFC008;
    padding: 0.16rem 0.4rem;
    font-size: 0.2rem;
    border-radius: 0.13rem;
    cursor: pointer;
    color: #fff;
    transition: all 0.2s ease-in-out;
}

.ShapesScreen .btn:hover {
    background-color: #ff9100;
    transform: translateY(-2px);
}

.ShapesScreen .secction{
	padding-bottom: 0.7rem;
}
.ShapesScreen .car{
	width: 100%;
	height: 4.2rem;
    background: url(https://auto-clean.s3.eu-west-2.amazonaws.com/carui2.png) no-repeat;
	background-size: cover;
}
.ShapesScreen .name{
	font-size: 0.6rem;
	padding-top: 10%;
	padding-left: 0.5rem;
	font-weight: 600;
	width: 54%;
	line-height: 0.7rem;
	  color: #fff;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}
.ShapesScreen .uls{
	align-items: stretch;
	flex-wrap: wrap;
	width: 100%;
	padding-left: 0.6rem;
	margin: 0.8rem 0 0.4rem 0;
}
.ShapesScreen .ulBox{
	display: flex;
	flex-direction: column;
	text-align: center;
	width: 20%;
	margin: 0 5% 0.5rem 0;
	background-color: #fff;
}
.ShapesScreen .money{
	flex: 1;
	color: #747A81;
	font-size: 0.13rem;
	font-weight: 600;
	margin: 0.08rem 0 0.1rem 0;
	display: flex;
	flex-direction: row-reverse;
}
.ShapesScreen .btnImg{
	width: 0.2rem;
	height: 0.16rem;
	margin-right: 0.05rem;
}

.ShapesScreen .btnRight {
    /*flex: 1;*/
    padding: 0.11rem 0;
    border-radius: 0.14rem;
    background-color: rgb(255,192,8);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.ShapesScreen .btnRight:hover {
    background-color: rgb(255, 150, 0);
    transform: translateY(-2px);
}

@media screen and (max-width:935px){
	.ShapesScreen .ulBox{
		text-align: center;
		width: 30%;
		margin: 0 2% 0.5rem 0;
	}
	.ShapesScreen .uls{
		flex-wrap: wrap;
		width: 100%;
		margin: 0.6rem auto 0 auto;
		padding-left: 0.4rem;
	}
	.ShapesScreen .btn{
		display: none;
	}
	.ShapesScreen .cont{
		width: 90%;
	}
	.ShapesScreen .name{
		width: 80%;
	}
}
@media screen and (max-width:640px){
	.ShapesScreen .ulBox{
		text-align: center;
		width: 48%;
		margin: 0 1.5% 0.5rem 0;
	}
	.ShapesScreen .uls{
		flex-wrap: wrap;
		width: 100%;
		margin: 0.7rem auto 0 auto;
		padding-left: 0.2rem;
	}
	.ShapesScreen .cont{
		font-size: 0.2rem;
		line-height: 0.22axrem;
	}
	.ShapesScreen .name{
		font-size: 0.5rem;
	}
}
@media screen and (max-width:540px){
	.ShapesScreen .title{
		font-size: 0.6rem;
		width: 100%;
		line-height: 0.75rem;
		    font-family: Arial, sans-serif;
		        text-align: center;
font-weight: bold;
	}
.ShapesScreen .cont{
    color: #000;
    width: 50%;
    font-size: 0.2rem;
    line-height: 0.25rem;
    margin-top: 1rem;
    display: none;
}
	.ShapesScreen .car{
		height: 6rem;
	}
.ShapesScreen .name{
  font-size: 0.4rem;
  line-height: 0.6rem;
  width: 70%;
  padding-top: 34%;
  color: #FFFFFF;
  opacity: 1;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
}

	.ShapesScreen .ulBox{
		text-align: center;
		/*width: 80%;*/
		margin-bottom: 0.7rem;
	}
	.ShapesScreen .uls{
		width: 100%;
		margin: 0.6rem auto 0 auto;
		padding: 0;
		display: flex;
		justify-content: center;
		/*align-items: center;*/
	}
	.ShapesScreen .money{
		font-size: 0.2rem;
		margin: 0.08rem 0 0.2rem 0;
	}
	.ShapesScreen .btnRight{
		padding: 0.11rem 0;
		border-radius: 0.3rem;
		font-size: 0.22rem;
		font-weight: 600;
	}
	.ShapesScreen .btnImg{
		width: 0.55rem;
		height: 0.5rem;
	}

}
